import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const PartenaireCard = ({ data }) => {
  return (
    <div key={data.id} className="col-lg-4 col-md-6 mb-3" data-aos="fade-up">
      <Link to={`/partenaires/${data.slug}`}>
        <div className="card card-bordered card-transition shadow">
          <div className="mb-4">
            <GatsbyImage
              image={getImage(data.frontmatter.hero_image_16x9)}
              className="card-img-top"
              alt={data.frontmatter.hero_image_alt}
            />
          </div>
          <div className="card-body">
            <p className="h4 text-dark">{data.frontmatter.nom}</p>
          </div>
        </div>
      </Link>
     </div>
  )
}

export default PartenaireCard